import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import Button from '../Buttons';
import Title from '../Title';
import { ResourcesWrapper, ResourceCard } from './style';

const ResourcePost = (props) => {
  return (
    <ResourceCard>
      <div className="card-content">
        <article>
          <h2>{props.title}</h2>
          <div dangerouslySetInnerHTML={{ __html: props.desc }} />
          <Button
            as="a"
            href={props.link}
            btnText="Check it out"
            btnType="rounded-outline"
            filltype
          />
        </article>
      </div>

      <div className="card-img">
        <img src={props.img} alt={props.title} />
      </div>
    </ResourceCard>
  );
};

const index = ({ pageTitle, data }) => {
  return (
    <ResourcesWrapper>
      <div className="container">
        <Title
          title={pageTitle}
          desc="Get time-saving resources for your business built or compiled by the Optimum Futurist team."
          headingOf="page"
          align="center"
        />
        <div className="resources-container">
          {!data.allMarkdownRemark.nodes ? (
            <div>No resources available for download at the moment</div>
          ) : (
            data.allMarkdownRemark.nodes?.map((node) => {
              const { image, title, downloadlink } = node.frontmatter;
              return (
                <ResourcePost
                  key={uuidv4()}
                  img={image}
                  title={title}
                  desc={node.html}
                  link={downloadlink}
                />
              );
            })
          )}
        </div>
      </div>
    </ResourcesWrapper>
  );
};

ResourcePost.propTypes = {
  id: PropTypes.number,
  img: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  link: PropTypes.string,
};

export default index;
