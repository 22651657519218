import styled, { css } from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Link } from 'gatsby';

export const Button = styled.button`
  padding: 0.62rem 1.25rem;
  color: ${colors.cadetBlue};
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 1.25rem;

  ${(props) =>
    props.btnType === 'plain-btn' &&
    css`
      background: transparent;
      font-size: 1.1rem;
    `}

  ${(props) =>
    props.disabled === 'true' &&
    css`
      pointer-events: none;
    `}

    ${(props) =>
    props.hasIcon === true &&
    css`
      display: flex;

      & span {
        margin-left: 0.5rem;
      }
    `}

  ${(props) =>
    props.btnType === 'rounded-outline' &&
    css`
      padding: 0.9rem 2.1rem;
      border: 2px solid ${colors.cadetBlue};
      border-radius: 3rem;
      font-weight: 500;
      position: relative;
      z-index: 2;
      background-color: transparent;
      overflow: hidden;
      box-shadow: none;
      display: ${props.hasIcon ? 'flex' : 'inline-block'};
      align-items: center;

      &::after {
        content: '';
        position: absolute;
        top: -16px;
        left: 0;
        width: 120%;
        height: 200px;
        background-color: ${colors.white};
        border-color: ${colors.white}!important;
        border-radius: 50%;
        transform: translate(-10px, -70px) scale(0.1);
        opacity: 0;
        z-index: -1;
        transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
      }

      &:hover::after {
        opacity: 1;
        transform-origin: 100px 100px;
        transform: scale(1) translate(-10px, -80px);
      }

      &:hover {
        border-color: ${colors.white};
        color: ${colors.primaryDark};
      }

      &:active::after {
        opacity: 0.85;
      }
    `}
    
    
    ${(props) =>
    props.displayBp === 'laptop' &&
    css`
      display: none;

      @media ${device.laptop} {
        display: inline-block;
      }
    `}

    @media ${device.tablet} {
    min-width: ${(props) => props.minW};
  }
`;

export const CtaBtn = styled.button`
  color: ${(props) => (props.btnColor ? props.btnColor : colors.white)};
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in;

  & svg {
    margin-left: 1rem;
    color: ${colors.cadetBlue};
    font-size: 2rem;
  }

  &:hover {
    transform: translateX(4px);
    transition: all 0.3s ease-in;
  }

  @media ${device.laptopL} {
    margin-top: 3.6rem;
  }
  ${(props) =>
    props.fillType &&
    css`
      font-size: 1.2rem;
      background-color: ${colors.spaceCadetDark};
      padding: 0.6rem 1.2rem;
      border-radius: 2rem;
      @media ${device.laptopL} {
        margin-top: 4rem;
      }
    `}
`;

export const CtaLink = styled(Link)`
  font-size: 1.2rem;
  border-radius: 2rem;
  color: ${(props) => (props.color ? props.color : colors.white)};

  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  text-align: left;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease-in;

  & svg {
    margin-left: 1rem;
    color: ${colors.cadetBlue};
    font-size: 2rem;
  }

  &:hover {
    transform: translateX(4px);
    transition: all 0.3s ease-in;
    text-decoration: none;
  }
  @media ${device.laptopL} {
    margin-top: 4rem;
  }
`;

export const CtaLinkFillType = styled(CtaLink)`
  background-color: ${colors.spaceCadetDark};
  padding: 0.6rem 1.2rem;
`;

export const CtaLinkRoundedOutline = styled(CtaLink)`
  padding: 0.9rem 2.1rem;
  border: 2px solid ${colors.cadetBlue};
  border-radius: 3rem;
  font-weight: 500;
  position: relative;
  z-index: 2;
  background-color: transparent;
  overflow: hidden;
  box-shadow: none;
  margin-top: 2rem;

  &::after {
    content: '';
    position: absolute;
    top: -16px;
    left: 0;
    width: 120%;
    height: 200px;
    background-color: ${colors.white};
    border-color: transparent;
    border-radius: 50%;
    transform: translate(-10px, -70px) scale(0.1);
    opacity: 0;
    z-index: -1;
    transition: transform 0.3s, opacity 0.3s, background-color 0.3s;
  }

  &:hover::after {
    opacity: 1;
    transform-origin: 100px 100px;
    transform: scale(1) translate(-10px, -80px);
  }

  &:hover {
    color: ${colors.primaryDark};
    transform: none;
    border-color: ${colors.white};
  }

  &:active::after {
    opacity: 0.85;
  }
`;
