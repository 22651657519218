import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/layout';
import SEO from '../components/SEO';
import { ROUTES } from '../config/constants';
import Resources from '../components/Resources';
import Achievements from '../components/Achievements';

const resources = ({ data }) => {
  return (
    <Layout pageTitle="Resources">
      <SEO title={ROUTES.resources.title} description={ROUTES.resources.desc} />
      <Resources pageTitle="Resources" data={data} />
      <Achievements
        title="Let’s take your digital experience to the next level"
        desc="Our team includes certified, talented experts who provide exceptional service across several domains, including web development, UX & UI design, enterprise resource planning, and more. Need a project started today? Reach out to our team below, and we'll get back to you within twenty-four hours."
        highlightBorder={false}
        showStamp={false}
        showProjectRequest={false}
        hideOnMobile={true}
        maxw="44rem"
      />
    </Layout>
  );
};

export default resources;

export const query = graphql`
  query ResourcesListQuery($limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/resources/.*.md$/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
    ) {
      nodes {
        frontmatter {
          date
          title
          image
          downloadlink
        }
        excerpt
        html
        fields {
          slug
        }
      }
    }
  }
`;
