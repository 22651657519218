import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';

export const CompanyServiceWrapper = styled(Wrapper)``;

export const Card = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin: 0 -1rem 2rem;

  & .card-content,
  & .card-img {
    margin: 1rem 1rem;
  }

  .card-content {
    padding: 2rem 0;
  }

  button {
    display: none;
  }

  article {
    /* article number */
    & > :first-child {
      font-size: 1.4rem;
      color: ${colors.secondaryDark};
      margin-bottom: 2rem;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
    }
  }

  h3,
  h2 {
    font-size: 1.3rem;
    color: ${colors.cadetBlue};
    margin-bottom: 1.5rem;
  }

  & p {
    color: ${colors.lightGrey};
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }

  & img {
    width: 100%;
    border-radius: 1rem;
    height: 100%;
    object-fit: cover;
  }

  @media ${device.laptop} {
    flex-direction: row;
    padding: 0 0;
    & .card-content,
    & .card-img {
      width: 50%;
    }
    button {
      display: flex;
      justify-content: center;
    }

    .card-content {
      padding: 2rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
    }
  }

  @media ${device.laptopL} {
    & p {
      font-size: 1.2rem;
    }

    .card-content {
      padding-right: 6rem;
    }

    &:nth-of-type(2n) .card-content {
      padding-left: 6rem;
      padding-right: 2rem;
    }
  }

  @media ${device.desktopS} {
    h3 {
      font-size: 1.5rem;
    }
    .card-content {
      padding-right: 10rem;
      padding-left: 6rem;
    }

    &:nth-of-type(2n) .card-content {
      padding-left: 10rem;
      padding-right: 6rem;
    }
  }
`;
