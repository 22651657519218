import styled from 'styled-components';
import colors from '../../layout/globalColors';
import { device } from '../../layout/device';
import { Wrapper } from '../../layout/global-style';
import { Card } from '../CompanyServices/style';

export const ResourcesWrapper = styled(Wrapper)`
  .latest-blog-list {
    padding: 6rem 0 1rem;
  }

  .resources-container {
    margin-top: 4rem;
  }
`;

export const ResourceCard = styled(Card)`
  flex-direction: column-reverse;

  img {
    max-height: 480px;
    object-fit: cover;
    box-shadow: 0 0.9rem 50rem rgba(0, 0, 0, 0.6);
  }

  article {
    /* article number is not there first child is title */
    & > :first-child {
      font-size: 1.8rem;
      color: ${colors.cadetBlue};
      margin-bottom: 1.5rem;
      font-weight: 500;
    }
  }

  @media ${device.laptop} {
    &:nth-of-type(2n + 1) {
      flex-direction: row-reverse;
    }
    &:nth-of-type(2n) {
      flex-direction: row;
    }

    article {
      max-width: 32rem;
    }
  }

  @media ${device.laptopL} {
    .card-content {
      padding-right: 2rem;
      padding-left: 6rem;
    }

    &:nth-of-type(2n) .card-content {
      padding-right: 6rem;
      padding-left: 2rem;
    }
  }

  @media ${device.desktopS} {
    .card-content {
      padding-right: 6rem;
      padding-left: 10rem;
    }

    &:nth-of-type(2n) .card-content {
      padding-right: 10rem;
      padding-left: 6rem;
    }
  }

  & p {
    color: ${colors.lightGrey};
    margin-bottom: 2rem;
  }

  & img {
    width: 100%;
    border-radius: 1rem;
  }
`;
